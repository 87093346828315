<template>
  <div class="page-root">
    <page-title color="green" />

    <div class="error-title">
      予期せぬエラーが発生しました
    </div>
    <div class="error-description">
      エラーが発生したため、処理を中断しました。<br />
      お手数ですが、下記より再度お試しください。
    </div>

    <div class="button-block">
      <LinkButton color="green" :link="backLink">
        {{ buttonText }}
      </LinkButton>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/adhd/PageTitle";
import LinkButton from "@/components/adhd/LinkButton";
export default {
  name: "AdhdError",
  head: {
    title: {
      inner: "大人のADHDセルフチェック"
    }
  },
  props: {
    backLink: {
      type: String,
      required: false,
      default: "AdhdHome"
    }
  },
  computed: {
    buttonText() {
      if (this.backLink === "AdhdFirstScreening") {
        return "セルフチェックを最初からやり直す";
      } else if (this.backLink === "AdhdSecondScreening") {
        return "セルフ問診を最初からやり直す";
      }

      return "セルフチェックTOPに戻る";
    }
  },
  components: { PageTitle, LinkButton }
};
</script>

<style scope>
@import "../../assets/style/adhd.css";

.error-title {
  margin-top: 56px;
  font-size: 18px;
  font-weight: 700;
}

.error-description {
  margin: 24px 16px 32px 16px;
}

.button-block {
  margin: 16px;
}
</style>
